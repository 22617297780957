import axios from 'axios';

class InfoService {
    categories = null;
    sellers = null;

    init = async () => {
        console.log("[init] called");
        await this.fetchSellers();
        await this.fetchCategories();
    }

    fetchSellers = async ()=>{
        try {
            const ret = await axios.get(`https://rest.39f.jp/sellers`);
            console.log("[fetchSellers] ret", ret);
            this.sellers = ret.data?.data;
        } catch (e) {
            console.error("[fetchSellers] error", e);
            return [];
        }
    }

    fetchCategories = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.jp/categories`);
            console.log("[fetchCategories] ret", ret);
            this.categories = ret.data?.data?.categories;
        } catch (e) {
            console.error("[fetchCategories] error", e);
        }
    }

    getSellers = () => {
        return this.sellers;
    }

    getCategories = () => {
        return this.categories;
    }
}

export const infoService = new InfoService();
