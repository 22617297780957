import axios from "axios";
import SpinnerMedium from "components/Spinner/Medium";
import { formatDollar } from "components/Table/Simple/presets";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CategoryPage = () => {
    const { categoryId } = useParams();
    const navigate = useNavigate();
    const [productDatas, setProductDatas] = useState(null);
    const [categoryInfo, setCategoryInfo] = useState(null);
    // const [categories, setCategories] = useState(null);
    const [brands, setBrands] = useState(null);

    useEffect(() => {
        fetchData();
    }, [categoryId])

    const fetchData = async () => {
        try {
            const categoryRet = await axios.get(`https://rest.39f.jp/categories/${categoryId}`);
            console.log("[fetchData] categoryRet", categoryRet);
            setCategoryInfo(categoryRet.data.data);
            const ret = await axios.get(`https://rest.39f.jp/products/categories/${categoryId}`);
            console.log("[fetchData] ret", ret);
            const products = ret.data.data;
            setProductDatas(products);
            console.log("[fetchData] products", products);
            const tempBrands = _.uniqBy(products.map((product) => product.seller), 'sellerId');
            console.log("[fetchData] tempBrands", tempBrands);
            // setCategories(tempBrands);
            setBrands(tempBrands);
        } catch (e) {
            console.error(e);
        }
    }

    if (_.isNil(productDatas)) {
        return <SpinnerMedium />
    }

    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900">{categoryInfo.name}</h2>
                    </div>
                    <div>
                        {brands.map((brand) => (
                            <div>
                                <div className="md:flex md:items-center md:justify-between mt-5">
                                    <h2 className="text-xl font-bold tracking-tight text-gray-900">{brand.name}</h2>
                                </div>
                                <div className="mt-2 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 lg:gap-x-8">
                                    {productDatas.filter((product) => product.seller.sellerId === brand.sellerId).map((product) => (
                                        <div key={product.id} className="group relative">
                                            <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                                                <img
                                                    src={product.mainImage}
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <h3 className="mt-2 text-sm text-gray-700">
                                                <a className="cursor-pointer"
                                                    onClick={() => { navigate(`/product/${product.productId}`) }}
                                                >
                                                    <span className="absolute inset-0" />
                                                    {product.name}
                                                </a>
                                            </h3>
                                            <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                            <p className="mt-1 text-sm font-medium text-gray-900">{formatDollar(product.price)}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-8 text-sm md:hidden">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Shop the collection
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CategoryPage;