import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/


import { useState } from 'react'
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Radio,
    RadioGroup,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
} from '@headlessui/react'
import { StarIcon } from '@heroicons/react/20/solid'
import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import axios from "axios";
import { formatDollar } from "components/Table/Simple/presets";
import { useQuill } from "react-quilljs";
import QuillMadeArticle from "components/Article/QuillMade";
import Quill from "quill";
import { toast } from "react-toastify";

const product = {
    name: 'Zip Tote Basket',
    price: '$140',
    rating: 4,
    images: [
        {
            id: 1,
            name: 'Angled view',
            src: 'https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg',
            alt: 'Angled front view with bag zipped and handles upright.',
        },
        // More images...
    ],
    colors: [
        { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
        { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
        { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
    ],
    description: `
    <p>The Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
  `,
    details: [
        {
            name: 'Features',
            items: [
                'Multiple strap configurations',
                'Spacious interior with top zip',
                'Leather handle and tabs',
                'Interior dividers',
                'Stainless strap loops',
                'Double stitched construction',
                'Water-resistant',
            ],
        },
        // More sections...
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ProductDetail = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [quill] = useState(new Quill(document.createElement('div')));
    const [productData, setProductData] = useState(null);
    const [selectedColor, setSelectedColor] = useState(product.colors[0]);
    const [detailHtmlTxt, setDetailHtmlTxt] = useState(null);

    useEffect(() => {
        if (_.isNil(productId)) {
            return;
        }
        fetchProductDetail();
    }, [productId])

    useEffect(() => {
        if (_.isNil(quill) || _.isNil(productData)) {
            return;
        }
        const quillOps = productData?.detail?.quillOps;
        console.log("quillOps", quillOps);
        if(_.isNil(quillOps)){
            return;
        }
        quill.setContents(quillOps);
        const tempHtml = quill?.getSemanticHTML();
        console.log("quill2222", tempHtml);
        setDetailHtmlTxt(tempHtml);
    }, [quill, productData])

    const fetchProductDetail = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.jp/products/${productId}`);
            console.log("[fetchProductDetail] ret", ret);
            setProductData(ret.data.data);
            console.log("quill", ret.data.data.detail.quillOps);
        } catch (e) {

        }
    }
    const handleAddToCart = async () => {
        console.log("[handleAddToCart] called", productData);
        const body = {
            productId: productData.productId,
            quantity: 1
        }
        try{
            const ret = await axios.post(`https://rest.39f.jp/carts/msjeon/add`, body);
            console.log("[handleAddToCart] ret", ret);
            toast.success("Added to cart");
        }catch(e){
            console.error("[handleAddToCart] error", e);
            toast.error("Failed to add to cart");
        }
    }

    if (_.isNil(productData)) {
        return <SpinnerMedium />
    }

    const imagesUrls = [productData.mainImage, ...productData.images.map((image) => image.url)];
    console.log("detail", detailHtmlTxt);

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
                <a className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 mb-3 block cursor-pointer"
                    onClick={() => { navigate(-1) }}
                >
                    <span aria-hidden="true">&larr; </span>
                    Back
                </a>
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                    {/* Image gallery */}
                    <TabGroup className="flex flex-col-reverse">
                        {/* Image selector */}
                        <div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none hidden sm:block">
                            <TabList className="grid grid-cols-4 gap-6">
                                {imagesUrls.map((imageUrl, i) => (
                                    <Tab
                                        key={i}
                                        className="group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                    >
                                        {/* <span className="sr-only">{image}</span> */}
                                        <span className="absolute inset-0 overflow-hidden rounded-md">
                                            <img alt="" src={imageUrl} className="h-full w-full object-cover object-center" />
                                        </span>
                                        <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
                                        />
                                    </Tab>
                                ))}
                            </TabList>
                        </div>

                        <div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none block sm:hidden overflow-scroll">
                            <TabList className="flex space-x-2 py-4">
                                {imagesUrls.map((imageUrl, i) => (
                                    <Tab
                                        key={i}
                                        className="group relative cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                    >
                                        {/* <span className="sr-only">{image}</span> */}
                                        <div className="w-40 h-32">
                                            <span className="absolute inset-0 overflow-hidden rounded-md">
                                                <img alt="" src={imageUrl} className="h-full w-full object-cover object-center" />
                                            </span>
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
                                            />
                                        </div>
                                    </Tab>
                                ))}
                            </TabList>
                        </div>

                        <TabPanels className="aspect-h-1 aspect-w-1 w-full">
                            {imagesUrls.map((imageUrl, i) => (
                                <TabPanel key={i}>
                                    <img
                                        src={imageUrl}
                                        className="h-full w-full object-cover object-center sm:rounded-lg"
                                    />
                                </TabPanel>
                            ))}
                            {imagesUrls.map((imageUrl, i) => (
                                <TabPanel key={i}>
                                    <img
                                        src={imageUrl}
                                        className="h-full w-full object-cover object-center sm:rounded-lg"
                                    />
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </TabGroup>

                    {/* Product info */}
                    <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">{productData.name}</h1>

                        <div className="mt-3">
                            <h2 className="sr-only">Product information</h2>
                            <p className="text-3xl tracking-tight text-gray-900">{formatDollar(productData.price)}</p>
                        </div>

                        {/* Reviews */}
                        <div className="mt-3">
                            <h3 className="sr-only">Reviews</h3>
                            <div className="flex items-center">
                                <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                        <StarIcon
                                            key={rating}
                                            aria-hidden="true"
                                            className={classNames(
                                                product.rating > rating ? 'text-indigo-500' : 'text-gray-300',
                                                'h-5 w-5 flex-shrink-0',
                                            )}
                                        />
                                    ))}
                                </div>
                                <p className="sr-only">{product.rating} out of 5 stars</p>
                            </div>
                        </div>

                        <div className="mt-6">
                            <h3 className="sr-only">Description</h3>

                            <div
                                dangerouslySetInnerHTML={{ __html: productData.description }}
                                className="space-y-6 text-base text-gray-700"
                            />
                        </div>

                        <form className="mt-6">
                            {/* Colors */}
                            {/* <div>
                                <h3 className="text-sm font-medium text-gray-600">Color</h3>

                                <fieldset aria-label="Choose a color" className="mt-2">
                                    <RadioGroup value={selectedColor} onChange={setSelectedColor} className="flex items-center space-x-3">
                                        {product.colors.map((color) => (
                                            <Radio
                                                key={color.name}
                                                value={color}
                                                aria-label={color.name}
                                                className={classNames(
                                                    color.selectedColor,
                                                    'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-2 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1',
                                                )}
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        color.bgColor,
                                                        'h-8 w-8 rounded-full border border-black border-opacity-10',
                                                    )}
                                                />
                                            </Radio>
                                        ))}
                                    </RadioGroup>
                                </fieldset>
                            </div> */}

                            <div className="mt-10 flex">
                                <button
                                    type="submit"
                                    className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                                    onClick={(e)=>{e.preventDefault(); handleAddToCart()}}
                                >
                                    Add to bag
                                </button>

                                <button
                                    type="button"
                                    className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                                >
                                    <HeartIcon aria-hidden="true" className="h-6 w-6 flex-shrink-0" />
                                    <span className="sr-only">Add to favorites</span>
                                </button>
                            </div>
                        </form>

                        <section aria-labelledby="details-heading" className="mt-12">
                            <h2 id="details-heading" className="sr-only">
                                Additional details
                            </h2>

                            <div className="divide-y divide-gray-200 border-t">
                                <Disclosure as="div" defaultOpen={true}>
                                    <h3>
                                        <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                                            <span className="text-sm font-medium text-gray-900 group-data-[open]:text-indigo-600">
                                                {'Product details'}
                                            </span>
                                            <span className="ml-6 flex items-center">
                                                <PlusIcon
                                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                                                />
                                                <MinusIcon
                                                    className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                                                />
                                            </span>
                                        </DisclosureButton>
                                    </h3>
                                    <DisclosurePanel className="prose prose-sm pb-6">
                                        {/* <ul role="list">
                                                {detail.items.map((item) => (
                                                    <li key={item}>{item}</li>
                                                ))}
                                            </ul> */}
                                        <QuillMadeArticle htmlText={detailHtmlTxt} />
                                    </DisclosurePanel>
                                </Disclosure>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ProductDetail;