import React, { useEffect, useState } from 'react'
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import _ from 'lodash';
import SpinnerMedium from 'components/Spinner/Medium';
import { toast } from 'react-toastify';
import { formatDollar } from 'components/Table/Simple/presets';

const products = [
    {
        id: 1,
        name: 'Basic Tee',
        href: '#',
        price: '$32.00',
        color: 'Sienna',
        inStock: true,
        size: 'Large',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in sienna.",
    },
    {
        id: 2,
        name: 'Basic Tee',
        href: '#',
        price: '$32.00',
        color: 'Black',
        inStock: false,
        leadTime: '3–4 weeks',
        size: 'Large',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-02.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
    },
    {
        id: 3,
        name: 'Nomad Tumbler',
        href: '#',
        price: '$35.00',
        color: 'White',
        inStock: true,
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-03.jpg',
        imageAlt: 'Insulated bottle with white base and black snap lid.',
    },
]

export default function Example() {
    const [cartItems, setCartItems] = useState(null);
    const [productItems, setProductItems] = useState(null);

    useEffect(() => {
        fetchCartData();
    }, []);

    const fetchCartData = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.jp/carts/msjeon/item`);
            console.log("[fetchCartData] ret", ret);
            const tempCartItems = ret?.data?.data?.items;
            setCartItems(tempCartItems);
            console.log("[fetchCartData] tempCartItems", tempCartItems);
            const tempProductItems = _.uniqBy(tempCartItems.map((item) => item.product), 'productId');
            console.log("[fetchCartData] tempProducts", tempProductItems);
            setProductItems(tempProductItems);
        } catch (e) {

        }
    }

    const handleCheckout = async () => {
        const ok = window.confirm("Do you want to checkout?");
        if (!ok) {
            return;
        }
        console.log("[handleCheckout] items", productItems);
        const orderItems = productItems.map((product) => {
            return {
                productId: product.productId,
                quantity: 1,
                optionValueId: 0,
                price: Number(product.price),
            }
        });
        console.log("[handleCheckout] orderItems", orderItems);
        const totalPrice = orderItems.reduce((acc, item) => {
            return acc + item.price;
        }, 0);
        const body = {
            status: "PENDING",
            items: orderItems,
            totalPrice: totalPrice
        }
        try {
            const ret = await axios.post(`https://rest.39f.jp/orders/msjeon`, body);
            console.log("[handleCheckout] ret", ret);
            toast.success("Checkout success");
        } catch (e) {
            console.error("[handleCheckout] error", e);
            toast.error("Checkout failed");
        }
    }

    const handleRemove = async (productId) => {
        console.log("[handleRemove] productId", productId);
        // /carts/{userId}/item/products/{productId}
        try {
            const ret = await axios.delete(`https://rest.39f.jp/carts/msjeon/item/products/${productId}`);
            console.log("[handleRemove] ret", ret);
            toast.success("Remove success");
            fetchCartData();
        } catch (e) {
            console.error("[handleRemove] error", e);
            toast.error("Remove failed");
        }
    }

    if (_.isNil(cartItems)) {
        return <SpinnerMedium />
    }

    if (_.isNil(productItems)) {
        return <SpinnerMedium />
    }

    const priceSum = productItems.reduce((acc, product) => {
        return acc + Number(product.price);
    }, 0);

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 pb-24 pt-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Shopping Cart</h1>
                <form className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                    <section aria-labelledby="cart-heading" className="lg:col-span-7">
                        <h2 id="cart-heading" className="sr-only">
                            Items in your shopping cart
                        </h2>

                        <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                            {productItems.map((product, productIdx) => (
                                <li key={product.productId} className="flex py-6 sm:py-10">
                                    <div className="flex-shrink-0">
                                        <img
                                            alt={product.name}
                                            src={product.mainImage}
                                            className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                                        />
                                    </div>

                                    <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                            <div>
                                                <div className="flex justify-between">
                                                    <h3 className="text-sm">
                                                        <a href={`#`} className="font-medium text-gray-700 hover:text-gray-800">
                                                            {product.name}
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div className="mt-1 flex text-sm">
                                                    <p className="text-gray-500">{product.color}</p>
                                                    {product.size ? (
                                                        <p className="ml-4 border-l border-gray-200 pl-4 text-gray-500">{product.size}</p>
                                                    ) : null}
                                                </div>
                                                <p className="mt-1 text-sm font-medium text-gray-900">{formatDollar(product.price)}</p>
                                            </div>

                                            <div className="mt-4 sm:mt-0 sm:pr-9">
                                                <label htmlFor={`quantity-${productIdx}`} className="sr-only">
                                                    Quantity, {product.name}
                                                </label>
                                                <select
                                                    id={`quantity-${productIdx}`}
                                                    name={`quantity-${productIdx}`}
                                                    className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                </select>

                                                <div className="absolute right-0 top-0">
                                                    <button type="button" className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            handleRemove(product.productId);
                                                        }}
                                                    >
                                                        <span className="sr-only">Remove</span>
                                                        <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="mt-4 flex space-x-2 text-sm text-gray-700">
                                            {product.inStock ? (
                                                <CheckIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-green-500" />
                                            ) : (
                                                <ClockIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-300" />
                                            )}

                                            <span>{product.inStock ? 'In stock' : `Ships in 1 Day`}</span>
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Order summary */}
                    <section
                        aria-labelledby="summary-heading"
                        className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                    >
                        <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                            Order summary
                        </h2>

                        <dl className="mt-6 space-y-4">
                            <div className="flex items-center justify-between">
                                <dt className="text-sm text-gray-600">Subtotal</dt>
                                <dd className="text-sm font-medium text-gray-900">{`$${priceSum}`}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex items-center text-sm text-gray-600">
                                    <span>Shipping estimate</span>
                                    <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Learn more about how shipping is calculated</span>
                                        <QuestionMarkCircleIcon aria-hidden="true" className="h-5 w-5" />
                                    </a>
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">$5.00</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex text-sm text-gray-600">
                                    <span>Tax estimate</span>
                                    <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Learn more about how tax is calculated</span>
                                        <QuestionMarkCircleIcon aria-hidden="true" className="h-5 w-5" />
                                    </a>
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">{`$${priceSum / 10}`}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="text-base font-medium text-gray-900">Order total</dt>
                                <dd className="text-base font-medium text-gray-900">{`$${priceSum + priceSum / 10 + 5}`}</dd>
                            </div>
                        </dl>

                        <div className="mt-6">
                            <button
                                type="submit"
                                className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                onClick={(e) => { e.preventDefault(); handleCheckout(); }}
                            >
                                Checkout
                            </button>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    )
}
