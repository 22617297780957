import React from "react"

const SpinnerMedium = () => {
    return (
        <div className="m-auto size-12">
            <div className="size-12 border-4 border-solid border-indigo-500 border-t-transparent rounded-full animate-spin absolute">
            </div>
        </div>
    )
}

export default SpinnerMedium;

