
import React from "react";
import { Routes, Route } from "react-router-dom";

import ProductLists from "containers/Store/Product/Lists";
import ProductOverviews from "containers/Store/Product/Overviews";
import ProductFeatures from "containers/Store/Product/Features";
import ProductDetail from "containers/Store/Product/Detail";

export default () => (
    <Routes>
        <Route path="/lists" element={<ProductLists />}></Route>
        <Route path="/overviews" element={<ProductOverviews />}></Route>
        <Route path="/features" element={<ProductFeatures />}></Route>
        <Route path="/:productId" element={<ProductDetail />}></Route>
    </Routes>
)

