import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicSideNav from 'containers/SideNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';
import PublicTopNav from 'containers/TopNav/public';
import { infoService } from 'services/info';
import SpinnerMedium from 'components/Spinner/Medium';

import "./App.scss";

function App() {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        try {
            await infoService.init();
            setIsReady(true);
        } catch (e) {
            console.error("[init] error", e);
        }
    }

    if (!isReady) {
        return <SpinnerMedium></SpinnerMedium>
    }
    return (
        <div className="">
            <PublicTopNav></PublicTopNav>
            {/* <PublicSideNav></PublicSideNav> */}
            <div className="mx-auto max-w-7xl">
                <PublicSiteRoute></PublicSiteRoute>
            </div>
        </div>
    );
}

export default App;