import React from 'react';
import styles from '../../Form/Editor/Quill/Basic/styles.module.scss';

const QuillMadeArticle = ({ htmlText }) => {
    return (
        <article className={`prose prose-sm prose-p:my-1 prose-p:min-h-4 prose-ul:my-0 min-w-full ${styles.customSize} overflow-scroll`}>
            <div dangerouslySetInnerHTML={{ __html: htmlText }} ></div>
        </article>
    )
}

export default QuillMadeArticle;
